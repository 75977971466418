<template>
    <v-container class="my-10">
        <div v-if="messageParams && docReady" class="text-center text-h4 primary--text status-message">
            <v-img class="mx-auto" max-width="250" :src="require('../static/magi.png')"></v-img>
            <v-divider class="my-5"></v-divider>
            <div :style="`border-color: ${messageParams.color};`" class="icon-cont mx-auto pt-2">
                <v-icon :color="messageParams.color" size="72">{{messageParams.icon}}</v-icon>
            </div>
            <p class="mt-5 text-capitalize approval-message">{{ messageParams.message }}</p>
        </div>
        <p v-else class="mt-5 text-capitalize text-center">404 - Nothing to show here.</p>
    </v-container>
</template>

<script>
export default {
  data () {
    return {
      docReady: true
    }
  },
  computed: {
    messageParams () {
      let params = {}
      switch (this.$route.query.approvalStatus) {
        case '0':
          params = { icon: 'mdi-account-check', color: '#52af50', message: 'this user is already approved before' }
          break
        case '1':
          params = { icon: 'mdi-account-star', color: '#FDD835', message: 'this user has been approved successfully' }
          break
        case '2':
          params = { icon: 'mdi-account-alert', color: '#ff5252', message: 'this user does not have agency details' }
          break
        case '3':
          params = { icon: 'mdi-web-cancel', color: '#ff5252', message: 'This website does not have membership program to approve this user' }
          break
        case '4':
          params = { icon: 'mdi-account-cancel', color: '#ff5252', message: 'This user is not registered or the account was deleted' }
          break
        default:
          params = null
          break
      }
      return params
    }
  },
  mounted () {
    if (!this.$route.query) this.docReady = false
    else this.docReady = true
  }
}
</script>

<style scoped>
.status-message {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.icon-cont {
    border: 5px solid #52af50;
    border-radius: 50%;
    width: 105px;
    height: 105px;
}
.approval-message {
    line-height: 55px;
}
</style>
